
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        
































































.router-link-active {
  font-weight: bold;
}

.tab--right {
  display: none;

  @media screen and (min-width: bp(xs)) {
    display: block;
  }
}

.tabs {
  @media screen and (min-width: bp(l)) {
    width: span(7, 0, span(10));
  }
  @media screen and (min-width: bp(xl)) {
    width: span(6, 0, span(10));
  }
}
